@import "functions";
@import "bootstrap/scss/functions";

@import "variables";
@import "vendor/lumen/variables";
@import "bootstrap/scss/variables";

@import "mixins";
@import "bootstrap/scss/mixins";

// Bootstrap
@import "bootstrap/scss/root";
@import "bootstrap/scss/reboot";
@import "bootstrap/scss/type";
@import "bootstrap/scss/grid";
@import "bootstrap/scss/jumbotron";
@import "bootstrap/scss/utilities";

// Theme
@import "vendor/lumen/bootswatch";

// Custom
@import "components/footer";
@import "components/main";
@import "components/site";

@import "https://fonts.googleapis.com/css2?family=Source+Sans+Pro:ital,wght@0,300;0,400;0,700;1,400&display=swap";
:root {
  --blue: #158cba;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #ff4136;
  --orange: #fd7e14;
  --yellow: #ff851b;
  --green: #28b62c;
  --teal: #20c997;
  --cyan: #75caeb;
  --white: #fff;
  --gray: #999;
  --gray-dark: #333;
  --primary: #158cba;
  --secondary: #f0f0f0;
  --success: #28b62c;
  --info: #75caeb;
  --warning: #ff851b;
  --danger: #ff4136;
  --light: #f6f6f6;
  --dark: #555;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: "Source Sans Pro", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

*, :before, :after {
  box-sizing: border-box;
}

html {
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  font-family: sans-serif;
  line-height: 1.15;
}

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block;
}

body {
  color: #222;
  text-align: left;
  background-color: #fff;
  margin: 0;
  font-family: Source Sans Pro, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  font-size: .875rem;
  font-weight: 400;
  line-height: 1.5;
}

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: .5rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title], abbr[data-original-title] {
  cursor: help;
  -webkit-text-decoration-skip-ink: none;
  text-decoration-skip-ink: none;
  border-bottom: 0;
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}

address {
  font-style: normal;
  line-height: inherit;
  margin-bottom: 1rem;
}

ol, ul, dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol, ul ul, ol ul, ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: .5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b, strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

a {
  color: #158cba;
  background-color: rgba(0, 0, 0, 0);
  text-decoration: none;
}

a:hover {
  color: #0d5875;
  text-decoration: underline;
}

a:not([href]):not([class]), a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre, code, kbd, samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

pre {
  -ms-overflow-style: scrollbar;
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
}

figure {
  margin: 0 0 1rem;
}

img {
  vertical-align: middle;
  border-style: none;
}

svg {
  vertical-align: middle;
  overflow: hidden;
}

table {
  border-collapse: collapse;
}

caption {
  color: #999;
  text-align: left;
  caption-side: bottom;
  padding-top: .75rem;
  padding-bottom: .75rem;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

label {
  margin-bottom: .5rem;
  display: inline-block;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input, button, select, optgroup, textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  margin: 0;
}

button, input {
  overflow: visible;
}

button, select {
  text-transform: none;
}

[role="button"] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
}

button:not(:disabled), [type="button"]:not(:disabled), [type="reset"]:not(:disabled), [type="submit"]:not(:disabled) {
  cursor: pointer;
}

button::-moz-focus-inner, [type="button"]::-moz-focus-inner, [type="reset"]::-moz-focus-inner, [type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

input[type="radio"], input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0;
}

textarea {
  resize: vertical;
  overflow: auto;
}

fieldset {
  min-width: 0;
  border: 0;
  margin: 0;
  padding: 0;
}

legend {
  width: 100%;
  max-width: 100%;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
  margin-bottom: .5rem;
  padding: 0;
  display: block;
}

@media (max-width: 1200px) {
  legend {
    font-size: calc(1.275rem + .3vw);
  }
}

progress {
  vertical-align: baseline;
}

[type="number"]::-webkit-inner-spin-button, [type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

summary {
  cursor: pointer;
  display: list-item;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: .5rem;
  font-weight: 500;
  line-height: 1.2;
}

h1, .h1 {
  font-size: 2.1875rem;
}

@media (max-width: 1200px) {
  h1, .h1 {
    font-size: calc(1.34375rem + 1.125vw);
  }
}

h2, .h2 {
  font-size: 1.75rem;
}

@media (max-width: 1200px) {
  h2, .h2 {
    font-size: calc(1.3rem + .6vw);
  }
}

h3, .h3 {
  font-size: 1.53125rem;
}

@media (max-width: 1200px) {
  h3, .h3 {
    font-size: calc(1.27813rem + .3375vw);
  }
}

h4, .h4 {
  font-size: 1.3125rem;
}

@media (max-width: 1200px) {
  h4, .h4 {
    font-size: calc(1.25625rem + .075vw);
  }
}

h5, .h5 {
  font-size: 1.09375rem;
}

h6, .h6 {
  font-size: .875rem;
}

.lead {
  font-size: 1.09375rem;
  font-weight: 300;
}

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2;
}

@media (max-width: 1200px) {
  .display-1 {
    font-size: calc(1.725rem + 5.7vw);
  }
}

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2;
}

@media (max-width: 1200px) {
  .display-2 {
    font-size: calc(1.675rem + 5.1vw);
  }
}

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2;
}

@media (max-width: 1200px) {
  .display-3 {
    font-size: calc(1.575rem + 3.9vw);
  }
}

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2;
}

@media (max-width: 1200px) {
  .display-4 {
    font-size: calc(1.475rem + 2.7vw);
  }
}

hr {
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, .1);
  margin-top: 1rem;
  margin-bottom: 1rem;
}

small, .small {
  font-size: 80%;
  font-weight: 400;
}

mark, .mark {
  background-color: #fcf8e3;
  padding: .2em;
}

.list-unstyled, .list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}

.list-inline-item:not(:last-child) {
  margin-right: .5rem;
}

.initialism {
  text-transform: uppercase;
  font-size: 90%;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.09375rem;
}

.blockquote-footer {
  color: #999;
  font-size: 80%;
  display: block;
}

.blockquote-footer:before {
  content: "— ";
}

.container, .container-fluid, .container-xl, .container-lg, .container-md, .container-sm {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-left: 15px;
  padding-right: 15px;
}

@media (min-width: 576px) {
  .container-sm, .container {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container-md, .container-sm, .container {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container-lg, .container-md, .container-sm, .container {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1140px;
  }
}

.row {
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px;
  display: flex;
}

.no-gutters {
  margin-left: 0;
  margin-right: 0;
}

.no-gutters > .col, .no-gutters > [class*="col-"] {
  padding-left: 0;
  padding-right: 0;
}

.col-xl, .col-xl-auto, .col-xl-12, .col-xl-11, .col-xl-10, .col-xl-9, .col-xl-8, .col-xl-7, .col-xl-6, .col-xl-5, .col-xl-4, .col-xl-3, .col-xl-2, .col-xl-1, .col-lg, .col-lg-auto, .col-lg-12, .col-lg-11, .col-lg-10, .col-lg-9, .col-lg-8, .col-lg-7, .col-lg-6, .col-lg-5, .col-lg-4, .col-lg-3, .col-lg-2, .col-lg-1, .col-md, .col-md-auto, .col-md-12, .col-md-11, .col-md-10, .col-md-9, .col-md-8, .col-md-7, .col-md-6, .col-md-5, .col-md-4, .col-md-3, .col-md-2, .col-md-1, .col-sm, .col-sm-auto, .col-sm-12, .col-sm-11, .col-sm-10, .col-sm-9, .col-sm-8, .col-sm-7, .col-sm-6, .col-sm-5, .col-sm-4, .col-sm-3, .col-sm-2, .col-sm-1, .col, .col-auto, .col-12, .col-11, .col-10, .col-9, .col-8, .col-7, .col-6, .col-5, .col-4, .col-3, .col-2, .col-1 {
  width: 100%;
  padding-left: 15px;
  padding-right: 15px;
  position: relative;
}

.col {
  max-width: 100%;
  flex-grow: 1;
  flex-basis: 0;
}

.row-cols-1 > * {
  max-width: 100%;
  flex: 0 0 100%;
}

.row-cols-2 > * {
  max-width: 50%;
  flex: 0 0 50%;
}

.row-cols-3 > * {
  max-width: 33.3333%;
  flex: 0 0 33.3333%;
}

.row-cols-4 > * {
  max-width: 25%;
  flex: 0 0 25%;
}

.row-cols-5 > * {
  max-width: 20%;
  flex: 0 0 20%;
}

.row-cols-6 > * {
  max-width: 16.6667%;
  flex: 0 0 16.6667%;
}

.col-auto {
  width: auto;
  max-width: 100%;
  flex: none;
}

.col-1 {
  max-width: 8.33333%;
  flex: 0 0 8.33333%;
}

.col-2 {
  max-width: 16.6667%;
  flex: 0 0 16.6667%;
}

.col-3 {
  max-width: 25%;
  flex: 0 0 25%;
}

.col-4 {
  max-width: 33.3333%;
  flex: 0 0 33.3333%;
}

.col-5 {
  max-width: 41.6667%;
  flex: 0 0 41.6667%;
}

.col-6 {
  max-width: 50%;
  flex: 0 0 50%;
}

.col-7 {
  max-width: 58.3333%;
  flex: 0 0 58.3333%;
}

.col-8 {
  max-width: 66.6667%;
  flex: 0 0 66.6667%;
}

.col-9 {
  max-width: 75%;
  flex: 0 0 75%;
}

.col-10 {
  max-width: 83.3333%;
  flex: 0 0 83.3333%;
}

.col-11 {
  max-width: 91.6667%;
  flex: 0 0 91.6667%;
}

.col-12 {
  max-width: 100%;
  flex: 0 0 100%;
}

.order-first {
  order: -1;
}

.order-last {
  order: 13;
}

.order-0 {
  order: 0;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

.order-4 {
  order: 4;
}

.order-5 {
  order: 5;
}

.order-6 {
  order: 6;
}

.order-7 {
  order: 7;
}

.order-8 {
  order: 8;
}

.order-9 {
  order: 9;
}

.order-10 {
  order: 10;
}

.order-11 {
  order: 11;
}

.order-12 {
  order: 12;
}

.offset-1 {
  margin-left: 8.33333%;
}

.offset-2 {
  margin-left: 16.6667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.3333%;
}

.offset-5 {
  margin-left: 41.6667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.3333%;
}

.offset-8 {
  margin-left: 66.6667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.3333%;
}

.offset-11 {
  margin-left: 91.6667%;
}

@media (min-width: 576px) {
  .col-sm {
    max-width: 100%;
    flex-grow: 1;
    flex-basis: 0;
  }

  .row-cols-sm-1 > * {
    max-width: 100%;
    flex: 0 0 100%;
  }

  .row-cols-sm-2 > * {
    max-width: 50%;
    flex: 0 0 50%;
  }

  .row-cols-sm-3 > * {
    max-width: 33.3333%;
    flex: 0 0 33.3333%;
  }

  .row-cols-sm-4 > * {
    max-width: 25%;
    flex: 0 0 25%;
  }

  .row-cols-sm-5 > * {
    max-width: 20%;
    flex: 0 0 20%;
  }

  .row-cols-sm-6 > * {
    max-width: 16.6667%;
    flex: 0 0 16.6667%;
  }

  .col-sm-auto {
    width: auto;
    max-width: 100%;
    flex: none;
  }

  .col-sm-1 {
    max-width: 8.33333%;
    flex: 0 0 8.33333%;
  }

  .col-sm-2 {
    max-width: 16.6667%;
    flex: 0 0 16.6667%;
  }

  .col-sm-3 {
    max-width: 25%;
    flex: 0 0 25%;
  }

  .col-sm-4 {
    max-width: 33.3333%;
    flex: 0 0 33.3333%;
  }

  .col-sm-5 {
    max-width: 41.6667%;
    flex: 0 0 41.6667%;
  }

  .col-sm-6 {
    max-width: 50%;
    flex: 0 0 50%;
  }

  .col-sm-7 {
    max-width: 58.3333%;
    flex: 0 0 58.3333%;
  }

  .col-sm-8 {
    max-width: 66.6667%;
    flex: 0 0 66.6667%;
  }

  .col-sm-9 {
    max-width: 75%;
    flex: 0 0 75%;
  }

  .col-sm-10 {
    max-width: 83.3333%;
    flex: 0 0 83.3333%;
  }

  .col-sm-11 {
    max-width: 91.6667%;
    flex: 0 0 91.6667%;
  }

  .col-sm-12 {
    max-width: 100%;
    flex: 0 0 100%;
  }

  .order-sm-first {
    order: -1;
  }

  .order-sm-last {
    order: 13;
  }

  .order-sm-0 {
    order: 0;
  }

  .order-sm-1 {
    order: 1;
  }

  .order-sm-2 {
    order: 2;
  }

  .order-sm-3 {
    order: 3;
  }

  .order-sm-4 {
    order: 4;
  }

  .order-sm-5 {
    order: 5;
  }

  .order-sm-6 {
    order: 6;
  }

  .order-sm-7 {
    order: 7;
  }

  .order-sm-8 {
    order: 8;
  }

  .order-sm-9 {
    order: 9;
  }

  .order-sm-10 {
    order: 10;
  }

  .order-sm-11 {
    order: 11;
  }

  .order-sm-12 {
    order: 12;
  }

  .offset-sm-0 {
    margin-left: 0;
  }

  .offset-sm-1 {
    margin-left: 8.33333%;
  }

  .offset-sm-2 {
    margin-left: 16.6667%;
  }

  .offset-sm-3 {
    margin-left: 25%;
  }

  .offset-sm-4 {
    margin-left: 33.3333%;
  }

  .offset-sm-5 {
    margin-left: 41.6667%;
  }

  .offset-sm-6 {
    margin-left: 50%;
  }

  .offset-sm-7 {
    margin-left: 58.3333%;
  }

  .offset-sm-8 {
    margin-left: 66.6667%;
  }

  .offset-sm-9 {
    margin-left: 75%;
  }

  .offset-sm-10 {
    margin-left: 83.3333%;
  }

  .offset-sm-11 {
    margin-left: 91.6667%;
  }
}

@media (min-width: 768px) {
  .col-md {
    max-width: 100%;
    flex-grow: 1;
    flex-basis: 0;
  }

  .row-cols-md-1 > * {
    max-width: 100%;
    flex: 0 0 100%;
  }

  .row-cols-md-2 > * {
    max-width: 50%;
    flex: 0 0 50%;
  }

  .row-cols-md-3 > * {
    max-width: 33.3333%;
    flex: 0 0 33.3333%;
  }

  .row-cols-md-4 > * {
    max-width: 25%;
    flex: 0 0 25%;
  }

  .row-cols-md-5 > * {
    max-width: 20%;
    flex: 0 0 20%;
  }

  .row-cols-md-6 > * {
    max-width: 16.6667%;
    flex: 0 0 16.6667%;
  }

  .col-md-auto {
    width: auto;
    max-width: 100%;
    flex: none;
  }

  .col-md-1 {
    max-width: 8.33333%;
    flex: 0 0 8.33333%;
  }

  .col-md-2 {
    max-width: 16.6667%;
    flex: 0 0 16.6667%;
  }

  .col-md-3 {
    max-width: 25%;
    flex: 0 0 25%;
  }

  .col-md-4 {
    max-width: 33.3333%;
    flex: 0 0 33.3333%;
  }

  .col-md-5 {
    max-width: 41.6667%;
    flex: 0 0 41.6667%;
  }

  .col-md-6 {
    max-width: 50%;
    flex: 0 0 50%;
  }

  .col-md-7 {
    max-width: 58.3333%;
    flex: 0 0 58.3333%;
  }

  .col-md-8 {
    max-width: 66.6667%;
    flex: 0 0 66.6667%;
  }

  .col-md-9 {
    max-width: 75%;
    flex: 0 0 75%;
  }

  .col-md-10 {
    max-width: 83.3333%;
    flex: 0 0 83.3333%;
  }

  .col-md-11 {
    max-width: 91.6667%;
    flex: 0 0 91.6667%;
  }

  .col-md-12 {
    max-width: 100%;
    flex: 0 0 100%;
  }

  .order-md-first {
    order: -1;
  }

  .order-md-last {
    order: 13;
  }

  .order-md-0 {
    order: 0;
  }

  .order-md-1 {
    order: 1;
  }

  .order-md-2 {
    order: 2;
  }

  .order-md-3 {
    order: 3;
  }

  .order-md-4 {
    order: 4;
  }

  .order-md-5 {
    order: 5;
  }

  .order-md-6 {
    order: 6;
  }

  .order-md-7 {
    order: 7;
  }

  .order-md-8 {
    order: 8;
  }

  .order-md-9 {
    order: 9;
  }

  .order-md-10 {
    order: 10;
  }

  .order-md-11 {
    order: 11;
  }

  .order-md-12 {
    order: 12;
  }

  .offset-md-0 {
    margin-left: 0;
  }

  .offset-md-1 {
    margin-left: 8.33333%;
  }

  .offset-md-2 {
    margin-left: 16.6667%;
  }

  .offset-md-3 {
    margin-left: 25%;
  }

  .offset-md-4 {
    margin-left: 33.3333%;
  }

  .offset-md-5 {
    margin-left: 41.6667%;
  }

  .offset-md-6 {
    margin-left: 50%;
  }

  .offset-md-7 {
    margin-left: 58.3333%;
  }

  .offset-md-8 {
    margin-left: 66.6667%;
  }

  .offset-md-9 {
    margin-left: 75%;
  }

  .offset-md-10 {
    margin-left: 83.3333%;
  }

  .offset-md-11 {
    margin-left: 91.6667%;
  }
}

@media (min-width: 992px) {
  .col-lg {
    max-width: 100%;
    flex-grow: 1;
    flex-basis: 0;
  }

  .row-cols-lg-1 > * {
    max-width: 100%;
    flex: 0 0 100%;
  }

  .row-cols-lg-2 > * {
    max-width: 50%;
    flex: 0 0 50%;
  }

  .row-cols-lg-3 > * {
    max-width: 33.3333%;
    flex: 0 0 33.3333%;
  }

  .row-cols-lg-4 > * {
    max-width: 25%;
    flex: 0 0 25%;
  }

  .row-cols-lg-5 > * {
    max-width: 20%;
    flex: 0 0 20%;
  }

  .row-cols-lg-6 > * {
    max-width: 16.6667%;
    flex: 0 0 16.6667%;
  }

  .col-lg-auto {
    width: auto;
    max-width: 100%;
    flex: none;
  }

  .col-lg-1 {
    max-width: 8.33333%;
    flex: 0 0 8.33333%;
  }

  .col-lg-2 {
    max-width: 16.6667%;
    flex: 0 0 16.6667%;
  }

  .col-lg-3 {
    max-width: 25%;
    flex: 0 0 25%;
  }

  .col-lg-4 {
    max-width: 33.3333%;
    flex: 0 0 33.3333%;
  }

  .col-lg-5 {
    max-width: 41.6667%;
    flex: 0 0 41.6667%;
  }

  .col-lg-6 {
    max-width: 50%;
    flex: 0 0 50%;
  }

  .col-lg-7 {
    max-width: 58.3333%;
    flex: 0 0 58.3333%;
  }

  .col-lg-8 {
    max-width: 66.6667%;
    flex: 0 0 66.6667%;
  }

  .col-lg-9 {
    max-width: 75%;
    flex: 0 0 75%;
  }

  .col-lg-10 {
    max-width: 83.3333%;
    flex: 0 0 83.3333%;
  }

  .col-lg-11 {
    max-width: 91.6667%;
    flex: 0 0 91.6667%;
  }

  .col-lg-12 {
    max-width: 100%;
    flex: 0 0 100%;
  }

  .order-lg-first {
    order: -1;
  }

  .order-lg-last {
    order: 13;
  }

  .order-lg-0 {
    order: 0;
  }

  .order-lg-1 {
    order: 1;
  }

  .order-lg-2 {
    order: 2;
  }

  .order-lg-3 {
    order: 3;
  }

  .order-lg-4 {
    order: 4;
  }

  .order-lg-5 {
    order: 5;
  }

  .order-lg-6 {
    order: 6;
  }

  .order-lg-7 {
    order: 7;
  }

  .order-lg-8 {
    order: 8;
  }

  .order-lg-9 {
    order: 9;
  }

  .order-lg-10 {
    order: 10;
  }

  .order-lg-11 {
    order: 11;
  }

  .order-lg-12 {
    order: 12;
  }

  .offset-lg-0 {
    margin-left: 0;
  }

  .offset-lg-1 {
    margin-left: 8.33333%;
  }

  .offset-lg-2 {
    margin-left: 16.6667%;
  }

  .offset-lg-3 {
    margin-left: 25%;
  }

  .offset-lg-4 {
    margin-left: 33.3333%;
  }

  .offset-lg-5 {
    margin-left: 41.6667%;
  }

  .offset-lg-6 {
    margin-left: 50%;
  }

  .offset-lg-7 {
    margin-left: 58.3333%;
  }

  .offset-lg-8 {
    margin-left: 66.6667%;
  }

  .offset-lg-9 {
    margin-left: 75%;
  }

  .offset-lg-10 {
    margin-left: 83.3333%;
  }

  .offset-lg-11 {
    margin-left: 91.6667%;
  }
}

@media (min-width: 1200px) {
  .col-xl {
    max-width: 100%;
    flex-grow: 1;
    flex-basis: 0;
  }

  .row-cols-xl-1 > * {
    max-width: 100%;
    flex: 0 0 100%;
  }

  .row-cols-xl-2 > * {
    max-width: 50%;
    flex: 0 0 50%;
  }

  .row-cols-xl-3 > * {
    max-width: 33.3333%;
    flex: 0 0 33.3333%;
  }

  .row-cols-xl-4 > * {
    max-width: 25%;
    flex: 0 0 25%;
  }

  .row-cols-xl-5 > * {
    max-width: 20%;
    flex: 0 0 20%;
  }

  .row-cols-xl-6 > * {
    max-width: 16.6667%;
    flex: 0 0 16.6667%;
  }

  .col-xl-auto {
    width: auto;
    max-width: 100%;
    flex: none;
  }

  .col-xl-1 {
    max-width: 8.33333%;
    flex: 0 0 8.33333%;
  }

  .col-xl-2 {
    max-width: 16.6667%;
    flex: 0 0 16.6667%;
  }

  .col-xl-3 {
    max-width: 25%;
    flex: 0 0 25%;
  }

  .col-xl-4 {
    max-width: 33.3333%;
    flex: 0 0 33.3333%;
  }

  .col-xl-5 {
    max-width: 41.6667%;
    flex: 0 0 41.6667%;
  }

  .col-xl-6 {
    max-width: 50%;
    flex: 0 0 50%;
  }

  .col-xl-7 {
    max-width: 58.3333%;
    flex: 0 0 58.3333%;
  }

  .col-xl-8 {
    max-width: 66.6667%;
    flex: 0 0 66.6667%;
  }

  .col-xl-9 {
    max-width: 75%;
    flex: 0 0 75%;
  }

  .col-xl-10 {
    max-width: 83.3333%;
    flex: 0 0 83.3333%;
  }

  .col-xl-11 {
    max-width: 91.6667%;
    flex: 0 0 91.6667%;
  }

  .col-xl-12 {
    max-width: 100%;
    flex: 0 0 100%;
  }

  .order-xl-first {
    order: -1;
  }

  .order-xl-last {
    order: 13;
  }

  .order-xl-0 {
    order: 0;
  }

  .order-xl-1 {
    order: 1;
  }

  .order-xl-2 {
    order: 2;
  }

  .order-xl-3 {
    order: 3;
  }

  .order-xl-4 {
    order: 4;
  }

  .order-xl-5 {
    order: 5;
  }

  .order-xl-6 {
    order: 6;
  }

  .order-xl-7 {
    order: 7;
  }

  .order-xl-8 {
    order: 8;
  }

  .order-xl-9 {
    order: 9;
  }

  .order-xl-10 {
    order: 10;
  }

  .order-xl-11 {
    order: 11;
  }

  .order-xl-12 {
    order: 12;
  }

  .offset-xl-0 {
    margin-left: 0;
  }

  .offset-xl-1 {
    margin-left: 8.33333%;
  }

  .offset-xl-2 {
    margin-left: 16.6667%;
  }

  .offset-xl-3 {
    margin-left: 25%;
  }

  .offset-xl-4 {
    margin-left: 33.3333%;
  }

  .offset-xl-5 {
    margin-left: 41.6667%;
  }

  .offset-xl-6 {
    margin-left: 50%;
  }

  .offset-xl-7 {
    margin-left: 58.3333%;
  }

  .offset-xl-8 {
    margin-left: 66.6667%;
  }

  .offset-xl-9 {
    margin-left: 75%;
  }

  .offset-xl-10 {
    margin-left: 83.3333%;
  }

  .offset-xl-11 {
    margin-left: 91.6667%;
  }
}

.jumbotron {
  background-color: #fafafa;
  border-radius: .3rem;
  margin-bottom: 2rem;
  padding: 2rem 1rem;
}

@media (min-width: 576px) {
  .jumbotron {
    padding: 4rem 2rem;
  }
}

.jumbotron-fluid {
  border-radius: 0;
  padding-left: 0;
  padding-right: 0;
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.bg-primary {
  background-color: #158cba !important;
}

a.bg-primary:hover, a.bg-primary:focus, button.bg-primary:hover, button.bg-primary:focus {
  background-color: #106a8c !important;
}

.bg-secondary {
  background-color: #f0f0f0 !important;
}

a.bg-secondary:hover, a.bg-secondary:focus, button.bg-secondary:hover, button.bg-secondary:focus {
  background-color: #d7d7d7 !important;
}

.bg-success {
  background-color: #28b62c !important;
}

a.bg-success:hover, a.bg-success:focus, button.bg-success:hover, button.bg-success:focus {
  background-color: #1f8c22 !important;
}

.bg-info {
  background-color: #75caeb !important;
}

a.bg-info:hover, a.bg-info:focus, button.bg-info:hover, button.bg-info:focus {
  background-color: #48b9e5 !important;
}

.bg-warning {
  background-color: #ff851b !important;
}

a.bg-warning:hover, a.bg-warning:focus, button.bg-warning:hover, button.bg-warning:focus {
  background-color: #e76b00 !important;
}

.bg-danger {
  background-color: #ff4136 !important;
}

a.bg-danger:hover, a.bg-danger:focus, button.bg-danger:hover, button.bg-danger:focus {
  background-color: #ff1103 !important;
}

.bg-light {
  background-color: #f6f6f6 !important;
}

a.bg-light:hover, a.bg-light:focus, button.bg-light:hover, button.bg-light:focus {
  background-color: #ddd !important;
}

.bg-dark {
  background-color: #555 !important;
}

a.bg-dark:hover, a.bg-dark:focus, button.bg-dark:hover, button.bg-dark:focus {
  background-color: #3c3c3c !important;
}

.bg-white {
  background-color: #fff !important;
}

.bg-transparent {
  background-color: rgba(0, 0, 0, 0) !important;
}

.border {
  border: 1px solid #dee2e6 !important;
}

.border-top {
  border-top: 1px solid #dee2e6 !important;
}

.border-right {
  border-right: 1px solid #dee2e6 !important;
}

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important;
}

.border-left {
  border-left: 1px solid #dee2e6 !important;
}

.border-0 {
  border: 0 !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-right-0 {
  border-right: 0 !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-left-0 {
  border-left: 0 !important;
}

.border-primary {
  border-color: #158cba !important;
}

.border-secondary {
  border-color: #f0f0f0 !important;
}

.border-success {
  border-color: #28b62c !important;
}

.border-info {
  border-color: #75caeb !important;
}

.border-warning {
  border-color: #ff851b !important;
}

.border-danger {
  border-color: #ff4136 !important;
}

.border-light {
  border-color: #f6f6f6 !important;
}

.border-dark {
  border-color: #555 !important;
}

.border-white {
  border-color: #fff !important;
}

.rounded-sm {
  border-radius: .2rem !important;
}

.rounded {
  border-radius: .25rem !important;
}

.rounded-top {
  border-top-left-radius: .25rem !important;
  border-top-right-radius: .25rem !important;
}

.rounded-right {
  border-top-right-radius: .25rem !important;
  border-bottom-right-radius: .25rem !important;
}

.rounded-bottom {
  border-bottom-right-radius: .25rem !important;
  border-bottom-left-radius: .25rem !important;
}

.rounded-left {
  border-top-left-radius: .25rem !important;
  border-bottom-left-radius: .25rem !important;
}

.rounded-lg {
  border-radius: .3rem !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: 50rem !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.clearfix:after {
  clear: both;
  content: "";
  display: block;
}

.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important;
  }

  .d-sm-inline {
    display: inline !important;
  }

  .d-sm-inline-block {
    display: inline-block !important;
  }

  .d-sm-block {
    display: block !important;
  }

  .d-sm-table {
    display: table !important;
  }

  .d-sm-table-row {
    display: table-row !important;
  }

  .d-sm-table-cell {
    display: table-cell !important;
  }

  .d-sm-flex {
    display: flex !important;
  }

  .d-sm-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 768px) {
  .d-md-none {
    display: none !important;
  }

  .d-md-inline {
    display: inline !important;
  }

  .d-md-inline-block {
    display: inline-block !important;
  }

  .d-md-block {
    display: block !important;
  }

  .d-md-table {
    display: table !important;
  }

  .d-md-table-row {
    display: table-row !important;
  }

  .d-md-table-cell {
    display: table-cell !important;
  }

  .d-md-flex {
    display: flex !important;
  }

  .d-md-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important;
  }

  .d-lg-inline {
    display: inline !important;
  }

  .d-lg-inline-block {
    display: inline-block !important;
  }

  .d-lg-block {
    display: block !important;
  }

  .d-lg-table {
    display: table !important;
  }

  .d-lg-table-row {
    display: table-row !important;
  }

  .d-lg-table-cell {
    display: table-cell !important;
  }

  .d-lg-flex {
    display: flex !important;
  }

  .d-lg-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important;
  }

  .d-xl-inline {
    display: inline !important;
  }

  .d-xl-inline-block {
    display: inline-block !important;
  }

  .d-xl-block {
    display: block !important;
  }

  .d-xl-table {
    display: table !important;
  }

  .d-xl-table-row {
    display: table-row !important;
  }

  .d-xl-table-cell {
    display: table-cell !important;
  }

  .d-xl-flex {
    display: flex !important;
  }

  .d-xl-inline-flex {
    display: inline-flex !important;
  }
}

@media print {
  .d-print-none {
    display: none !important;
  }

  .d-print-inline {
    display: inline !important;
  }

  .d-print-inline-block {
    display: inline-block !important;
  }

  .d-print-block {
    display: block !important;
  }

  .d-print-table {
    display: table !important;
  }

  .d-print-table-row {
    display: table-row !important;
  }

  .d-print-table-cell {
    display: table-cell !important;
  }

  .d-print-flex {
    display: flex !important;
  }

  .d-print-inline-flex {
    display: inline-flex !important;
  }
}

.embed-responsive {
  width: 100%;
  padding: 0;
  display: block;
  position: relative;
  overflow: hidden;
}

.embed-responsive:before {
  content: "";
  display: block;
}

.embed-responsive .embed-responsive-item, .embed-responsive iframe, .embed-responsive embed, .embed-responsive object, .embed-responsive video {
  width: 100%;
  height: 100%;
  border: 0;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
}

.embed-responsive-21by9:before {
  padding-top: 42.8571%;
}

.embed-responsive-16by9:before {
  padding-top: 56.25%;
}

.embed-responsive-4by3:before {
  padding-top: 75%;
}

.embed-responsive-1by1:before {
  padding-top: 100%;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.flex-fill {
  flex: auto !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important;
  }

  .flex-sm-column {
    flex-direction: column !important;
  }

  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }

  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-sm-fill {
    flex: auto !important;
  }

  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-sm-start {
    justify-content: flex-start !important;
  }

  .justify-content-sm-end {
    justify-content: flex-end !important;
  }

  .justify-content-sm-center {
    justify-content: center !important;
  }

  .justify-content-sm-between {
    justify-content: space-between !important;
  }

  .justify-content-sm-around {
    justify-content: space-around !important;
  }

  .align-items-sm-start {
    align-items: flex-start !important;
  }

  .align-items-sm-end {
    align-items: flex-end !important;
  }

  .align-items-sm-center {
    align-items: center !important;
  }

  .align-items-sm-baseline {
    align-items: baseline !important;
  }

  .align-items-sm-stretch {
    align-items: stretch !important;
  }

  .align-content-sm-start {
    align-content: flex-start !important;
  }

  .align-content-sm-end {
    align-content: flex-end !important;
  }

  .align-content-sm-center {
    align-content: center !important;
  }

  .align-content-sm-between {
    align-content: space-between !important;
  }

  .align-content-sm-around {
    align-content: space-around !important;
  }

  .align-content-sm-stretch {
    align-content: stretch !important;
  }

  .align-self-sm-auto {
    align-self: auto !important;
  }

  .align-self-sm-start {
    align-self: flex-start !important;
  }

  .align-self-sm-end {
    align-self: flex-end !important;
  }

  .align-self-sm-center {
    align-self: center !important;
  }

  .align-self-sm-baseline {
    align-self: baseline !important;
  }

  .align-self-sm-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important;
  }

  .flex-md-column {
    flex-direction: column !important;
  }

  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-md-wrap {
    flex-wrap: wrap !important;
  }

  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-md-fill {
    flex: auto !important;
  }

  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-md-start {
    justify-content: flex-start !important;
  }

  .justify-content-md-end {
    justify-content: flex-end !important;
  }

  .justify-content-md-center {
    justify-content: center !important;
  }

  .justify-content-md-between {
    justify-content: space-between !important;
  }

  .justify-content-md-around {
    justify-content: space-around !important;
  }

  .align-items-md-start {
    align-items: flex-start !important;
  }

  .align-items-md-end {
    align-items: flex-end !important;
  }

  .align-items-md-center {
    align-items: center !important;
  }

  .align-items-md-baseline {
    align-items: baseline !important;
  }

  .align-items-md-stretch {
    align-items: stretch !important;
  }

  .align-content-md-start {
    align-content: flex-start !important;
  }

  .align-content-md-end {
    align-content: flex-end !important;
  }

  .align-content-md-center {
    align-content: center !important;
  }

  .align-content-md-between {
    align-content: space-between !important;
  }

  .align-content-md-around {
    align-content: space-around !important;
  }

  .align-content-md-stretch {
    align-content: stretch !important;
  }

  .align-self-md-auto {
    align-self: auto !important;
  }

  .align-self-md-start {
    align-self: flex-start !important;
  }

  .align-self-md-end {
    align-self: flex-end !important;
  }

  .align-self-md-center {
    align-self: center !important;
  }

  .align-self-md-baseline {
    align-self: baseline !important;
  }

  .align-self-md-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important;
  }

  .flex-lg-column {
    flex-direction: column !important;
  }

  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }

  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-lg-fill {
    flex: auto !important;
  }

  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-lg-start {
    justify-content: flex-start !important;
  }

  .justify-content-lg-end {
    justify-content: flex-end !important;
  }

  .justify-content-lg-center {
    justify-content: center !important;
  }

  .justify-content-lg-between {
    justify-content: space-between !important;
  }

  .justify-content-lg-around {
    justify-content: space-around !important;
  }

  .align-items-lg-start {
    align-items: flex-start !important;
  }

  .align-items-lg-end {
    align-items: flex-end !important;
  }

  .align-items-lg-center {
    align-items: center !important;
  }

  .align-items-lg-baseline {
    align-items: baseline !important;
  }

  .align-items-lg-stretch {
    align-items: stretch !important;
  }

  .align-content-lg-start {
    align-content: flex-start !important;
  }

  .align-content-lg-end {
    align-content: flex-end !important;
  }

  .align-content-lg-center {
    align-content: center !important;
  }

  .align-content-lg-between {
    align-content: space-between !important;
  }

  .align-content-lg-around {
    align-content: space-around !important;
  }

  .align-content-lg-stretch {
    align-content: stretch !important;
  }

  .align-self-lg-auto {
    align-self: auto !important;
  }

  .align-self-lg-start {
    align-self: flex-start !important;
  }

  .align-self-lg-end {
    align-self: flex-end !important;
  }

  .align-self-lg-center {
    align-self: center !important;
  }

  .align-self-lg-baseline {
    align-self: baseline !important;
  }

  .align-self-lg-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important;
  }

  .flex-xl-column {
    flex-direction: column !important;
  }

  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }

  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-xl-fill {
    flex: auto !important;
  }

  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-xl-start {
    justify-content: flex-start !important;
  }

  .justify-content-xl-end {
    justify-content: flex-end !important;
  }

  .justify-content-xl-center {
    justify-content: center !important;
  }

  .justify-content-xl-between {
    justify-content: space-between !important;
  }

  .justify-content-xl-around {
    justify-content: space-around !important;
  }

  .align-items-xl-start {
    align-items: flex-start !important;
  }

  .align-items-xl-end {
    align-items: flex-end !important;
  }

  .align-items-xl-center {
    align-items: center !important;
  }

  .align-items-xl-baseline {
    align-items: baseline !important;
  }

  .align-items-xl-stretch {
    align-items: stretch !important;
  }

  .align-content-xl-start {
    align-content: flex-start !important;
  }

  .align-content-xl-end {
    align-content: flex-end !important;
  }

  .align-content-xl-center {
    align-content: center !important;
  }

  .align-content-xl-between {
    align-content: space-between !important;
  }

  .align-content-xl-around {
    align-content: space-around !important;
  }

  .align-content-xl-stretch {
    align-content: stretch !important;
  }

  .align-self-xl-auto {
    align-self: auto !important;
  }

  .align-self-xl-start {
    align-self: flex-start !important;
  }

  .align-self-xl-end {
    align-self: flex-end !important;
  }

  .align-self-xl-center {
    align-self: center !important;
  }

  .align-self-xl-baseline {
    align-self: baseline !important;
  }

  .align-self-xl-stretch {
    align-self: stretch !important;
  }
}

.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.float-none {
  float: none !important;
}

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important;
  }

  .float-sm-right {
    float: right !important;
  }

  .float-sm-none {
    float: none !important;
  }
}

@media (min-width: 768px) {
  .float-md-left {
    float: left !important;
  }

  .float-md-right {
    float: right !important;
  }

  .float-md-none {
    float: none !important;
  }
}

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important;
  }

  .float-lg-right {
    float: right !important;
  }

  .float-lg-none {
    float: none !important;
  }
}

@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important;
  }

  .float-xl-right {
    float: right !important;
  }

  .float-xl-none {
    float: none !important;
  }
}

.user-select-all {
  -webkit-user-select: all !important;
  -ms-user-select: all !important;
  user-select: all !important;
}

.user-select-auto {
  -webkit-user-select: auto !important;
  -ms-user-select: auto !important;
  user-select: auto !important;
}

.user-select-none {
  -webkit-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.fixed-top {
  z-index: 1030;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
}

.fixed-bottom {
  z-index: 1030;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
}

@supports (position: sticky) {
  .sticky-top {
    z-index: 1020;
    position: sticky;
    top: 0;
  }
}

.sr-only {
  width: 1px;
  height: 1px;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
  margin: -1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

.sr-only-focusable:active, .sr-only-focusable:focus {
  width: auto;
  height: auto;
  clip: auto;
  white-space: normal;
  position: static;
  overflow: visible;
}

.shadow-sm {
  box-shadow: 0 .125rem .25rem rgba(0, 0, 0, .075) !important;
}

.shadow {
  box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .15) !important;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, .176) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.mh-100 {
  max-height: 100% !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.vw-100 {
  width: 100vw !important;
}

.vh-100 {
  height: 100vh !important;
}

.m-0 {
  margin: 0 !important;
}

.mt-0, .my-0 {
  margin-top: 0 !important;
}

.mr-0, .mx-0 {
  margin-right: 0 !important;
}

.mb-0, .my-0 {
  margin-bottom: 0 !important;
}

.ml-0, .mx-0 {
  margin-left: 0 !important;
}

.m-1 {
  margin: .25rem !important;
}

.mt-1, .my-1 {
  margin-top: .25rem !important;
}

.mr-1, .mx-1 {
  margin-right: .25rem !important;
}

.mb-1, .my-1 {
  margin-bottom: .25rem !important;
}

.ml-1, .mx-1 {
  margin-left: .25rem !important;
}

.m-2 {
  margin: .5rem !important;
}

.mt-2, .my-2 {
  margin-top: .5rem !important;
}

.mr-2, .mx-2 {
  margin-right: .5rem !important;
}

.mb-2, .my-2 {
  margin-bottom: .5rem !important;
}

.ml-2, .mx-2 {
  margin-left: .5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.mt-3, .my-3 {
  margin-top: 1rem !important;
}

.mr-3, .mx-3 {
  margin-right: 1rem !important;
}

.mb-3, .my-3 {
  margin-bottom: 1rem !important;
}

.ml-3, .mx-3 {
  margin-left: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.mt-4, .my-4 {
  margin-top: 1.5rem !important;
}

.mr-4, .mx-4 {
  margin-right: 1.5rem !important;
}

.mb-4, .my-4 {
  margin-bottom: 1.5rem !important;
}

.ml-4, .mx-4 {
  margin-left: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.mt-5, .my-5 {
  margin-top: 3rem !important;
}

.mr-5, .mx-5 {
  margin-right: 3rem !important;
}

.mb-5, .my-5 {
  margin-bottom: 3rem !important;
}

.ml-5, .mx-5 {
  margin-left: 3rem !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0, .py-0 {
  padding-top: 0 !important;
}

.pr-0, .px-0 {
  padding-right: 0 !important;
}

.pb-0, .py-0 {
  padding-bottom: 0 !important;
}

.pl-0, .px-0 {
  padding-left: 0 !important;
}

.p-1 {
  padding: .25rem !important;
}

.pt-1, .py-1 {
  padding-top: .25rem !important;
}

.pr-1, .px-1 {
  padding-right: .25rem !important;
}

.pb-1, .py-1 {
  padding-bottom: .25rem !important;
}

.pl-1, .px-1 {
  padding-left: .25rem !important;
}

.p-2 {
  padding: .5rem !important;
}

.pt-2, .py-2 {
  padding-top: .5rem !important;
}

.pr-2, .px-2 {
  padding-right: .5rem !important;
}

.pb-2, .py-2 {
  padding-bottom: .5rem !important;
}

.pl-2, .px-2 {
  padding-left: .5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.pt-3, .py-3 {
  padding-top: 1rem !important;
}

.pr-3, .px-3 {
  padding-right: 1rem !important;
}

.pb-3, .py-3 {
  padding-bottom: 1rem !important;
}

.pl-3, .px-3 {
  padding-left: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.pt-4, .py-4 {
  padding-top: 1.5rem !important;
}

.pr-4, .px-4 {
  padding-right: 1.5rem !important;
}

.pb-4, .py-4 {
  padding-bottom: 1.5rem !important;
}

.pl-4, .px-4 {
  padding-left: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.pt-5, .py-5 {
  padding-top: 3rem !important;
}

.pr-5, .px-5 {
  padding-right: 3rem !important;
}

.pb-5, .py-5 {
  padding-bottom: 3rem !important;
}

.pl-5, .px-5 {
  padding-left: 3rem !important;
}

.m-n1 {
  margin: -.25rem !important;
}

.mt-n1, .my-n1 {
  margin-top: -.25rem !important;
}

.mr-n1, .mx-n1 {
  margin-right: -.25rem !important;
}

.mb-n1, .my-n1 {
  margin-bottom: -.25rem !important;
}

.ml-n1, .mx-n1 {
  margin-left: -.25rem !important;
}

.m-n2 {
  margin: -.5rem !important;
}

.mt-n2, .my-n2 {
  margin-top: -.5rem !important;
}

.mr-n2, .mx-n2 {
  margin-right: -.5rem !important;
}

.mb-n2, .my-n2 {
  margin-bottom: -.5rem !important;
}

.ml-n2, .mx-n2 {
  margin-left: -.5rem !important;
}

.m-n3 {
  margin: -1rem !important;
}

.mt-n3, .my-n3 {
  margin-top: -1rem !important;
}

.mr-n3, .mx-n3 {
  margin-right: -1rem !important;
}

.mb-n3, .my-n3 {
  margin-bottom: -1rem !important;
}

.ml-n3, .mx-n3 {
  margin-left: -1rem !important;
}

.m-n4 {
  margin: -1.5rem !important;
}

.mt-n4, .my-n4 {
  margin-top: -1.5rem !important;
}

.mr-n4, .mx-n4 {
  margin-right: -1.5rem !important;
}

.mb-n4, .my-n4 {
  margin-bottom: -1.5rem !important;
}

.ml-n4, .mx-n4 {
  margin-left: -1.5rem !important;
}

.m-n5 {
  margin: -3rem !important;
}

.mt-n5, .my-n5 {
  margin-top: -3rem !important;
}

.mr-n5, .mx-n5 {
  margin-right: -3rem !important;
}

.mb-n5, .my-n5 {
  margin-bottom: -3rem !important;
}

.ml-n5, .mx-n5 {
  margin-left: -3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mt-auto, .my-auto {
  margin-top: auto !important;
}

.mr-auto, .mx-auto {
  margin-right: auto !important;
}

.mb-auto, .my-auto {
  margin-bottom: auto !important;
}

.ml-auto, .mx-auto {
  margin-left: auto !important;
}

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important;
  }

  .mt-sm-0, .my-sm-0 {
    margin-top: 0 !important;
  }

  .mr-sm-0, .mx-sm-0 {
    margin-right: 0 !important;
  }

  .mb-sm-0, .my-sm-0 {
    margin-bottom: 0 !important;
  }

  .ml-sm-0, .mx-sm-0 {
    margin-left: 0 !important;
  }

  .m-sm-1 {
    margin: .25rem !important;
  }

  .mt-sm-1, .my-sm-1 {
    margin-top: .25rem !important;
  }

  .mr-sm-1, .mx-sm-1 {
    margin-right: .25rem !important;
  }

  .mb-sm-1, .my-sm-1 {
    margin-bottom: .25rem !important;
  }

  .ml-sm-1, .mx-sm-1 {
    margin-left: .25rem !important;
  }

  .m-sm-2 {
    margin: .5rem !important;
  }

  .mt-sm-2, .my-sm-2 {
    margin-top: .5rem !important;
  }

  .mr-sm-2, .mx-sm-2 {
    margin-right: .5rem !important;
  }

  .mb-sm-2, .my-sm-2 {
    margin-bottom: .5rem !important;
  }

  .ml-sm-2, .mx-sm-2 {
    margin-left: .5rem !important;
  }

  .m-sm-3 {
    margin: 1rem !important;
  }

  .mt-sm-3, .my-sm-3 {
    margin-top: 1rem !important;
  }

  .mr-sm-3, .mx-sm-3 {
    margin-right: 1rem !important;
  }

  .mb-sm-3, .my-sm-3 {
    margin-bottom: 1rem !important;
  }

  .ml-sm-3, .mx-sm-3 {
    margin-left: 1rem !important;
  }

  .m-sm-4 {
    margin: 1.5rem !important;
  }

  .mt-sm-4, .my-sm-4 {
    margin-top: 1.5rem !important;
  }

  .mr-sm-4, .mx-sm-4 {
    margin-right: 1.5rem !important;
  }

  .mb-sm-4, .my-sm-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-sm-4, .mx-sm-4 {
    margin-left: 1.5rem !important;
  }

  .m-sm-5 {
    margin: 3rem !important;
  }

  .mt-sm-5, .my-sm-5 {
    margin-top: 3rem !important;
  }

  .mr-sm-5, .mx-sm-5 {
    margin-right: 3rem !important;
  }

  .mb-sm-5, .my-sm-5 {
    margin-bottom: 3rem !important;
  }

  .ml-sm-5, .mx-sm-5 {
    margin-left: 3rem !important;
  }

  .p-sm-0 {
    padding: 0 !important;
  }

  .pt-sm-0, .py-sm-0 {
    padding-top: 0 !important;
  }

  .pr-sm-0, .px-sm-0 {
    padding-right: 0 !important;
  }

  .pb-sm-0, .py-sm-0 {
    padding-bottom: 0 !important;
  }

  .pl-sm-0, .px-sm-0 {
    padding-left: 0 !important;
  }

  .p-sm-1 {
    padding: .25rem !important;
  }

  .pt-sm-1, .py-sm-1 {
    padding-top: .25rem !important;
  }

  .pr-sm-1, .px-sm-1 {
    padding-right: .25rem !important;
  }

  .pb-sm-1, .py-sm-1 {
    padding-bottom: .25rem !important;
  }

  .pl-sm-1, .px-sm-1 {
    padding-left: .25rem !important;
  }

  .p-sm-2 {
    padding: .5rem !important;
  }

  .pt-sm-2, .py-sm-2 {
    padding-top: .5rem !important;
  }

  .pr-sm-2, .px-sm-2 {
    padding-right: .5rem !important;
  }

  .pb-sm-2, .py-sm-2 {
    padding-bottom: .5rem !important;
  }

  .pl-sm-2, .px-sm-2 {
    padding-left: .5rem !important;
  }

  .p-sm-3 {
    padding: 1rem !important;
  }

  .pt-sm-3, .py-sm-3 {
    padding-top: 1rem !important;
  }

  .pr-sm-3, .px-sm-3 {
    padding-right: 1rem !important;
  }

  .pb-sm-3, .py-sm-3 {
    padding-bottom: 1rem !important;
  }

  .pl-sm-3, .px-sm-3 {
    padding-left: 1rem !important;
  }

  .p-sm-4 {
    padding: 1.5rem !important;
  }

  .pt-sm-4, .py-sm-4 {
    padding-top: 1.5rem !important;
  }

  .pr-sm-4, .px-sm-4 {
    padding-right: 1.5rem !important;
  }

  .pb-sm-4, .py-sm-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-sm-4, .px-sm-4 {
    padding-left: 1.5rem !important;
  }

  .p-sm-5 {
    padding: 3rem !important;
  }

  .pt-sm-5, .py-sm-5 {
    padding-top: 3rem !important;
  }

  .pr-sm-5, .px-sm-5 {
    padding-right: 3rem !important;
  }

  .pb-sm-5, .py-sm-5 {
    padding-bottom: 3rem !important;
  }

  .pl-sm-5, .px-sm-5 {
    padding-left: 3rem !important;
  }

  .m-sm-n1 {
    margin: -.25rem !important;
  }

  .mt-sm-n1, .my-sm-n1 {
    margin-top: -.25rem !important;
  }

  .mr-sm-n1, .mx-sm-n1 {
    margin-right: -.25rem !important;
  }

  .mb-sm-n1, .my-sm-n1 {
    margin-bottom: -.25rem !important;
  }

  .ml-sm-n1, .mx-sm-n1 {
    margin-left: -.25rem !important;
  }

  .m-sm-n2 {
    margin: -.5rem !important;
  }

  .mt-sm-n2, .my-sm-n2 {
    margin-top: -.5rem !important;
  }

  .mr-sm-n2, .mx-sm-n2 {
    margin-right: -.5rem !important;
  }

  .mb-sm-n2, .my-sm-n2 {
    margin-bottom: -.5rem !important;
  }

  .ml-sm-n2, .mx-sm-n2 {
    margin-left: -.5rem !important;
  }

  .m-sm-n3 {
    margin: -1rem !important;
  }

  .mt-sm-n3, .my-sm-n3 {
    margin-top: -1rem !important;
  }

  .mr-sm-n3, .mx-sm-n3 {
    margin-right: -1rem !important;
  }

  .mb-sm-n3, .my-sm-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-sm-n3, .mx-sm-n3 {
    margin-left: -1rem !important;
  }

  .m-sm-n4 {
    margin: -1.5rem !important;
  }

  .mt-sm-n4, .my-sm-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-sm-n4, .mx-sm-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-sm-n4, .my-sm-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-sm-n4, .mx-sm-n4 {
    margin-left: -1.5rem !important;
  }

  .m-sm-n5 {
    margin: -3rem !important;
  }

  .mt-sm-n5, .my-sm-n5 {
    margin-top: -3rem !important;
  }

  .mr-sm-n5, .mx-sm-n5 {
    margin-right: -3rem !important;
  }

  .mb-sm-n5, .my-sm-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-sm-n5, .mx-sm-n5 {
    margin-left: -3rem !important;
  }

  .m-sm-auto {
    margin: auto !important;
  }

  .mt-sm-auto, .my-sm-auto {
    margin-top: auto !important;
  }

  .mr-sm-auto, .mx-sm-auto {
    margin-right: auto !important;
  }

  .mb-sm-auto, .my-sm-auto {
    margin-bottom: auto !important;
  }

  .ml-sm-auto, .mx-sm-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important;
  }

  .mt-md-0, .my-md-0 {
    margin-top: 0 !important;
  }

  .mr-md-0, .mx-md-0 {
    margin-right: 0 !important;
  }

  .mb-md-0, .my-md-0 {
    margin-bottom: 0 !important;
  }

  .ml-md-0, .mx-md-0 {
    margin-left: 0 !important;
  }

  .m-md-1 {
    margin: .25rem !important;
  }

  .mt-md-1, .my-md-1 {
    margin-top: .25rem !important;
  }

  .mr-md-1, .mx-md-1 {
    margin-right: .25rem !important;
  }

  .mb-md-1, .my-md-1 {
    margin-bottom: .25rem !important;
  }

  .ml-md-1, .mx-md-1 {
    margin-left: .25rem !important;
  }

  .m-md-2 {
    margin: .5rem !important;
  }

  .mt-md-2, .my-md-2 {
    margin-top: .5rem !important;
  }

  .mr-md-2, .mx-md-2 {
    margin-right: .5rem !important;
  }

  .mb-md-2, .my-md-2 {
    margin-bottom: .5rem !important;
  }

  .ml-md-2, .mx-md-2 {
    margin-left: .5rem !important;
  }

  .m-md-3 {
    margin: 1rem !important;
  }

  .mt-md-3, .my-md-3 {
    margin-top: 1rem !important;
  }

  .mr-md-3, .mx-md-3 {
    margin-right: 1rem !important;
  }

  .mb-md-3, .my-md-3 {
    margin-bottom: 1rem !important;
  }

  .ml-md-3, .mx-md-3 {
    margin-left: 1rem !important;
  }

  .m-md-4 {
    margin: 1.5rem !important;
  }

  .mt-md-4, .my-md-4 {
    margin-top: 1.5rem !important;
  }

  .mr-md-4, .mx-md-4 {
    margin-right: 1.5rem !important;
  }

  .mb-md-4, .my-md-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-md-4, .mx-md-4 {
    margin-left: 1.5rem !important;
  }

  .m-md-5 {
    margin: 3rem !important;
  }

  .mt-md-5, .my-md-5 {
    margin-top: 3rem !important;
  }

  .mr-md-5, .mx-md-5 {
    margin-right: 3rem !important;
  }

  .mb-md-5, .my-md-5 {
    margin-bottom: 3rem !important;
  }

  .ml-md-5, .mx-md-5 {
    margin-left: 3rem !important;
  }

  .p-md-0 {
    padding: 0 !important;
  }

  .pt-md-0, .py-md-0 {
    padding-top: 0 !important;
  }

  .pr-md-0, .px-md-0 {
    padding-right: 0 !important;
  }

  .pb-md-0, .py-md-0 {
    padding-bottom: 0 !important;
  }

  .pl-md-0, .px-md-0 {
    padding-left: 0 !important;
  }

  .p-md-1 {
    padding: .25rem !important;
  }

  .pt-md-1, .py-md-1 {
    padding-top: .25rem !important;
  }

  .pr-md-1, .px-md-1 {
    padding-right: .25rem !important;
  }

  .pb-md-1, .py-md-1 {
    padding-bottom: .25rem !important;
  }

  .pl-md-1, .px-md-1 {
    padding-left: .25rem !important;
  }

  .p-md-2 {
    padding: .5rem !important;
  }

  .pt-md-2, .py-md-2 {
    padding-top: .5rem !important;
  }

  .pr-md-2, .px-md-2 {
    padding-right: .5rem !important;
  }

  .pb-md-2, .py-md-2 {
    padding-bottom: .5rem !important;
  }

  .pl-md-2, .px-md-2 {
    padding-left: .5rem !important;
  }

  .p-md-3 {
    padding: 1rem !important;
  }

  .pt-md-3, .py-md-3 {
    padding-top: 1rem !important;
  }

  .pr-md-3, .px-md-3 {
    padding-right: 1rem !important;
  }

  .pb-md-3, .py-md-3 {
    padding-bottom: 1rem !important;
  }

  .pl-md-3, .px-md-3 {
    padding-left: 1rem !important;
  }

  .p-md-4 {
    padding: 1.5rem !important;
  }

  .pt-md-4, .py-md-4 {
    padding-top: 1.5rem !important;
  }

  .pr-md-4, .px-md-4 {
    padding-right: 1.5rem !important;
  }

  .pb-md-4, .py-md-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-md-4, .px-md-4 {
    padding-left: 1.5rem !important;
  }

  .p-md-5 {
    padding: 3rem !important;
  }

  .pt-md-5, .py-md-5 {
    padding-top: 3rem !important;
  }

  .pr-md-5, .px-md-5 {
    padding-right: 3rem !important;
  }

  .pb-md-5, .py-md-5 {
    padding-bottom: 3rem !important;
  }

  .pl-md-5, .px-md-5 {
    padding-left: 3rem !important;
  }

  .m-md-n1 {
    margin: -.25rem !important;
  }

  .mt-md-n1, .my-md-n1 {
    margin-top: -.25rem !important;
  }

  .mr-md-n1, .mx-md-n1 {
    margin-right: -.25rem !important;
  }

  .mb-md-n1, .my-md-n1 {
    margin-bottom: -.25rem !important;
  }

  .ml-md-n1, .mx-md-n1 {
    margin-left: -.25rem !important;
  }

  .m-md-n2 {
    margin: -.5rem !important;
  }

  .mt-md-n2, .my-md-n2 {
    margin-top: -.5rem !important;
  }

  .mr-md-n2, .mx-md-n2 {
    margin-right: -.5rem !important;
  }

  .mb-md-n2, .my-md-n2 {
    margin-bottom: -.5rem !important;
  }

  .ml-md-n2, .mx-md-n2 {
    margin-left: -.5rem !important;
  }

  .m-md-n3 {
    margin: -1rem !important;
  }

  .mt-md-n3, .my-md-n3 {
    margin-top: -1rem !important;
  }

  .mr-md-n3, .mx-md-n3 {
    margin-right: -1rem !important;
  }

  .mb-md-n3, .my-md-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-md-n3, .mx-md-n3 {
    margin-left: -1rem !important;
  }

  .m-md-n4 {
    margin: -1.5rem !important;
  }

  .mt-md-n4, .my-md-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-md-n4, .mx-md-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-md-n4, .my-md-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-md-n4, .mx-md-n4 {
    margin-left: -1.5rem !important;
  }

  .m-md-n5 {
    margin: -3rem !important;
  }

  .mt-md-n5, .my-md-n5 {
    margin-top: -3rem !important;
  }

  .mr-md-n5, .mx-md-n5 {
    margin-right: -3rem !important;
  }

  .mb-md-n5, .my-md-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-md-n5, .mx-md-n5 {
    margin-left: -3rem !important;
  }

  .m-md-auto {
    margin: auto !important;
  }

  .mt-md-auto, .my-md-auto {
    margin-top: auto !important;
  }

  .mr-md-auto, .mx-md-auto {
    margin-right: auto !important;
  }

  .mb-md-auto, .my-md-auto {
    margin-bottom: auto !important;
  }

  .ml-md-auto, .mx-md-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important;
  }

  .mt-lg-0, .my-lg-0 {
    margin-top: 0 !important;
  }

  .mr-lg-0, .mx-lg-0 {
    margin-right: 0 !important;
  }

  .mb-lg-0, .my-lg-0 {
    margin-bottom: 0 !important;
  }

  .ml-lg-0, .mx-lg-0 {
    margin-left: 0 !important;
  }

  .m-lg-1 {
    margin: .25rem !important;
  }

  .mt-lg-1, .my-lg-1 {
    margin-top: .25rem !important;
  }

  .mr-lg-1, .mx-lg-1 {
    margin-right: .25rem !important;
  }

  .mb-lg-1, .my-lg-1 {
    margin-bottom: .25rem !important;
  }

  .ml-lg-1, .mx-lg-1 {
    margin-left: .25rem !important;
  }

  .m-lg-2 {
    margin: .5rem !important;
  }

  .mt-lg-2, .my-lg-2 {
    margin-top: .5rem !important;
  }

  .mr-lg-2, .mx-lg-2 {
    margin-right: .5rem !important;
  }

  .mb-lg-2, .my-lg-2 {
    margin-bottom: .5rem !important;
  }

  .ml-lg-2, .mx-lg-2 {
    margin-left: .5rem !important;
  }

  .m-lg-3 {
    margin: 1rem !important;
  }

  .mt-lg-3, .my-lg-3 {
    margin-top: 1rem !important;
  }

  .mr-lg-3, .mx-lg-3 {
    margin-right: 1rem !important;
  }

  .mb-lg-3, .my-lg-3 {
    margin-bottom: 1rem !important;
  }

  .ml-lg-3, .mx-lg-3 {
    margin-left: 1rem !important;
  }

  .m-lg-4 {
    margin: 1.5rem !important;
  }

  .mt-lg-4, .my-lg-4 {
    margin-top: 1.5rem !important;
  }

  .mr-lg-4, .mx-lg-4 {
    margin-right: 1.5rem !important;
  }

  .mb-lg-4, .my-lg-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-lg-4, .mx-lg-4 {
    margin-left: 1.5rem !important;
  }

  .m-lg-5 {
    margin: 3rem !important;
  }

  .mt-lg-5, .my-lg-5 {
    margin-top: 3rem !important;
  }

  .mr-lg-5, .mx-lg-5 {
    margin-right: 3rem !important;
  }

  .mb-lg-5, .my-lg-5 {
    margin-bottom: 3rem !important;
  }

  .ml-lg-5, .mx-lg-5 {
    margin-left: 3rem !important;
  }

  .p-lg-0 {
    padding: 0 !important;
  }

  .pt-lg-0, .py-lg-0 {
    padding-top: 0 !important;
  }

  .pr-lg-0, .px-lg-0 {
    padding-right: 0 !important;
  }

  .pb-lg-0, .py-lg-0 {
    padding-bottom: 0 !important;
  }

  .pl-lg-0, .px-lg-0 {
    padding-left: 0 !important;
  }

  .p-lg-1 {
    padding: .25rem !important;
  }

  .pt-lg-1, .py-lg-1 {
    padding-top: .25rem !important;
  }

  .pr-lg-1, .px-lg-1 {
    padding-right: .25rem !important;
  }

  .pb-lg-1, .py-lg-1 {
    padding-bottom: .25rem !important;
  }

  .pl-lg-1, .px-lg-1 {
    padding-left: .25rem !important;
  }

  .p-lg-2 {
    padding: .5rem !important;
  }

  .pt-lg-2, .py-lg-2 {
    padding-top: .5rem !important;
  }

  .pr-lg-2, .px-lg-2 {
    padding-right: .5rem !important;
  }

  .pb-lg-2, .py-lg-2 {
    padding-bottom: .5rem !important;
  }

  .pl-lg-2, .px-lg-2 {
    padding-left: .5rem !important;
  }

  .p-lg-3 {
    padding: 1rem !important;
  }

  .pt-lg-3, .py-lg-3 {
    padding-top: 1rem !important;
  }

  .pr-lg-3, .px-lg-3 {
    padding-right: 1rem !important;
  }

  .pb-lg-3, .py-lg-3 {
    padding-bottom: 1rem !important;
  }

  .pl-lg-3, .px-lg-3 {
    padding-left: 1rem !important;
  }

  .p-lg-4 {
    padding: 1.5rem !important;
  }

  .pt-lg-4, .py-lg-4 {
    padding-top: 1.5rem !important;
  }

  .pr-lg-4, .px-lg-4 {
    padding-right: 1.5rem !important;
  }

  .pb-lg-4, .py-lg-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-lg-4, .px-lg-4 {
    padding-left: 1.5rem !important;
  }

  .p-lg-5 {
    padding: 3rem !important;
  }

  .pt-lg-5, .py-lg-5 {
    padding-top: 3rem !important;
  }

  .pr-lg-5, .px-lg-5 {
    padding-right: 3rem !important;
  }

  .pb-lg-5, .py-lg-5 {
    padding-bottom: 3rem !important;
  }

  .pl-lg-5, .px-lg-5 {
    padding-left: 3rem !important;
  }

  .m-lg-n1 {
    margin: -.25rem !important;
  }

  .mt-lg-n1, .my-lg-n1 {
    margin-top: -.25rem !important;
  }

  .mr-lg-n1, .mx-lg-n1 {
    margin-right: -.25rem !important;
  }

  .mb-lg-n1, .my-lg-n1 {
    margin-bottom: -.25rem !important;
  }

  .ml-lg-n1, .mx-lg-n1 {
    margin-left: -.25rem !important;
  }

  .m-lg-n2 {
    margin: -.5rem !important;
  }

  .mt-lg-n2, .my-lg-n2 {
    margin-top: -.5rem !important;
  }

  .mr-lg-n2, .mx-lg-n2 {
    margin-right: -.5rem !important;
  }

  .mb-lg-n2, .my-lg-n2 {
    margin-bottom: -.5rem !important;
  }

  .ml-lg-n2, .mx-lg-n2 {
    margin-left: -.5rem !important;
  }

  .m-lg-n3 {
    margin: -1rem !important;
  }

  .mt-lg-n3, .my-lg-n3 {
    margin-top: -1rem !important;
  }

  .mr-lg-n3, .mx-lg-n3 {
    margin-right: -1rem !important;
  }

  .mb-lg-n3, .my-lg-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-lg-n3, .mx-lg-n3 {
    margin-left: -1rem !important;
  }

  .m-lg-n4 {
    margin: -1.5rem !important;
  }

  .mt-lg-n4, .my-lg-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-lg-n4, .mx-lg-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-lg-n4, .my-lg-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-lg-n4, .mx-lg-n4 {
    margin-left: -1.5rem !important;
  }

  .m-lg-n5 {
    margin: -3rem !important;
  }

  .mt-lg-n5, .my-lg-n5 {
    margin-top: -3rem !important;
  }

  .mr-lg-n5, .mx-lg-n5 {
    margin-right: -3rem !important;
  }

  .mb-lg-n5, .my-lg-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-lg-n5, .mx-lg-n5 {
    margin-left: -3rem !important;
  }

  .m-lg-auto {
    margin: auto !important;
  }

  .mt-lg-auto, .my-lg-auto {
    margin-top: auto !important;
  }

  .mr-lg-auto, .mx-lg-auto {
    margin-right: auto !important;
  }

  .mb-lg-auto, .my-lg-auto {
    margin-bottom: auto !important;
  }

  .ml-lg-auto, .mx-lg-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important;
  }

  .mt-xl-0, .my-xl-0 {
    margin-top: 0 !important;
  }

  .mr-xl-0, .mx-xl-0 {
    margin-right: 0 !important;
  }

  .mb-xl-0, .my-xl-0 {
    margin-bottom: 0 !important;
  }

  .ml-xl-0, .mx-xl-0 {
    margin-left: 0 !important;
  }

  .m-xl-1 {
    margin: .25rem !important;
  }

  .mt-xl-1, .my-xl-1 {
    margin-top: .25rem !important;
  }

  .mr-xl-1, .mx-xl-1 {
    margin-right: .25rem !important;
  }

  .mb-xl-1, .my-xl-1 {
    margin-bottom: .25rem !important;
  }

  .ml-xl-1, .mx-xl-1 {
    margin-left: .25rem !important;
  }

  .m-xl-2 {
    margin: .5rem !important;
  }

  .mt-xl-2, .my-xl-2 {
    margin-top: .5rem !important;
  }

  .mr-xl-2, .mx-xl-2 {
    margin-right: .5rem !important;
  }

  .mb-xl-2, .my-xl-2 {
    margin-bottom: .5rem !important;
  }

  .ml-xl-2, .mx-xl-2 {
    margin-left: .5rem !important;
  }

  .m-xl-3 {
    margin: 1rem !important;
  }

  .mt-xl-3, .my-xl-3 {
    margin-top: 1rem !important;
  }

  .mr-xl-3, .mx-xl-3 {
    margin-right: 1rem !important;
  }

  .mb-xl-3, .my-xl-3 {
    margin-bottom: 1rem !important;
  }

  .ml-xl-3, .mx-xl-3 {
    margin-left: 1rem !important;
  }

  .m-xl-4 {
    margin: 1.5rem !important;
  }

  .mt-xl-4, .my-xl-4 {
    margin-top: 1.5rem !important;
  }

  .mr-xl-4, .mx-xl-4 {
    margin-right: 1.5rem !important;
  }

  .mb-xl-4, .my-xl-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-xl-4, .mx-xl-4 {
    margin-left: 1.5rem !important;
  }

  .m-xl-5 {
    margin: 3rem !important;
  }

  .mt-xl-5, .my-xl-5 {
    margin-top: 3rem !important;
  }

  .mr-xl-5, .mx-xl-5 {
    margin-right: 3rem !important;
  }

  .mb-xl-5, .my-xl-5 {
    margin-bottom: 3rem !important;
  }

  .ml-xl-5, .mx-xl-5 {
    margin-left: 3rem !important;
  }

  .p-xl-0 {
    padding: 0 !important;
  }

  .pt-xl-0, .py-xl-0 {
    padding-top: 0 !important;
  }

  .pr-xl-0, .px-xl-0 {
    padding-right: 0 !important;
  }

  .pb-xl-0, .py-xl-0 {
    padding-bottom: 0 !important;
  }

  .pl-xl-0, .px-xl-0 {
    padding-left: 0 !important;
  }

  .p-xl-1 {
    padding: .25rem !important;
  }

  .pt-xl-1, .py-xl-1 {
    padding-top: .25rem !important;
  }

  .pr-xl-1, .px-xl-1 {
    padding-right: .25rem !important;
  }

  .pb-xl-1, .py-xl-1 {
    padding-bottom: .25rem !important;
  }

  .pl-xl-1, .px-xl-1 {
    padding-left: .25rem !important;
  }

  .p-xl-2 {
    padding: .5rem !important;
  }

  .pt-xl-2, .py-xl-2 {
    padding-top: .5rem !important;
  }

  .pr-xl-2, .px-xl-2 {
    padding-right: .5rem !important;
  }

  .pb-xl-2, .py-xl-2 {
    padding-bottom: .5rem !important;
  }

  .pl-xl-2, .px-xl-2 {
    padding-left: .5rem !important;
  }

  .p-xl-3 {
    padding: 1rem !important;
  }

  .pt-xl-3, .py-xl-3 {
    padding-top: 1rem !important;
  }

  .pr-xl-3, .px-xl-3 {
    padding-right: 1rem !important;
  }

  .pb-xl-3, .py-xl-3 {
    padding-bottom: 1rem !important;
  }

  .pl-xl-3, .px-xl-3 {
    padding-left: 1rem !important;
  }

  .p-xl-4 {
    padding: 1.5rem !important;
  }

  .pt-xl-4, .py-xl-4 {
    padding-top: 1.5rem !important;
  }

  .pr-xl-4, .px-xl-4 {
    padding-right: 1.5rem !important;
  }

  .pb-xl-4, .py-xl-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-xl-4, .px-xl-4 {
    padding-left: 1.5rem !important;
  }

  .p-xl-5 {
    padding: 3rem !important;
  }

  .pt-xl-5, .py-xl-5 {
    padding-top: 3rem !important;
  }

  .pr-xl-5, .px-xl-5 {
    padding-right: 3rem !important;
  }

  .pb-xl-5, .py-xl-5 {
    padding-bottom: 3rem !important;
  }

  .pl-xl-5, .px-xl-5 {
    padding-left: 3rem !important;
  }

  .m-xl-n1 {
    margin: -.25rem !important;
  }

  .mt-xl-n1, .my-xl-n1 {
    margin-top: -.25rem !important;
  }

  .mr-xl-n1, .mx-xl-n1 {
    margin-right: -.25rem !important;
  }

  .mb-xl-n1, .my-xl-n1 {
    margin-bottom: -.25rem !important;
  }

  .ml-xl-n1, .mx-xl-n1 {
    margin-left: -.25rem !important;
  }

  .m-xl-n2 {
    margin: -.5rem !important;
  }

  .mt-xl-n2, .my-xl-n2 {
    margin-top: -.5rem !important;
  }

  .mr-xl-n2, .mx-xl-n2 {
    margin-right: -.5rem !important;
  }

  .mb-xl-n2, .my-xl-n2 {
    margin-bottom: -.5rem !important;
  }

  .ml-xl-n2, .mx-xl-n2 {
    margin-left: -.5rem !important;
  }

  .m-xl-n3 {
    margin: -1rem !important;
  }

  .mt-xl-n3, .my-xl-n3 {
    margin-top: -1rem !important;
  }

  .mr-xl-n3, .mx-xl-n3 {
    margin-right: -1rem !important;
  }

  .mb-xl-n3, .my-xl-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-xl-n3, .mx-xl-n3 {
    margin-left: -1rem !important;
  }

  .m-xl-n4 {
    margin: -1.5rem !important;
  }

  .mt-xl-n4, .my-xl-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-xl-n4, .mx-xl-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-xl-n4, .my-xl-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-xl-n4, .mx-xl-n4 {
    margin-left: -1.5rem !important;
  }

  .m-xl-n5 {
    margin: -3rem !important;
  }

  .mt-xl-n5, .my-xl-n5 {
    margin-top: -3rem !important;
  }

  .mr-xl-n5, .mx-xl-n5 {
    margin-right: -3rem !important;
  }

  .mb-xl-n5, .my-xl-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-xl-n5, .mx-xl-n5 {
    margin-left: -3rem !important;
  }

  .m-xl-auto {
    margin: auto !important;
  }

  .mt-xl-auto, .my-xl-auto {
    margin-top: auto !important;
  }

  .mr-xl-auto, .mx-xl-auto {
    margin-right: auto !important;
  }

  .mb-xl-auto, .my-xl-auto {
    margin-bottom: auto !important;
  }

  .ml-xl-auto, .mx-xl-auto {
    margin-left: auto !important;
  }
}

.stretched-link:after {
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0);
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace !important;
}

.text-justify {
  text-align: justify !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-truncate {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important;
  }

  .text-sm-right {
    text-align: right !important;
  }

  .text-sm-center {
    text-align: center !important;
  }
}

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important;
  }

  .text-md-right {
    text-align: right !important;
  }

  .text-md-center {
    text-align: center !important;
  }
}

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important;
  }

  .text-lg-right {
    text-align: right !important;
  }

  .text-lg-center {
    text-align: center !important;
  }
}

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important;
  }

  .text-xl-right {
    text-align: right !important;
  }

  .text-xl-center {
    text-align: center !important;
  }
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.font-weight-light {
  font-weight: 300 !important;
}

.font-weight-lighter {
  font-weight: lighter !important;
}

.font-weight-normal {
  font-weight: 400 !important;
}

.font-weight-bold {
  font-weight: 700 !important;
}

.font-weight-bolder {
  font-weight: bolder !important;
}

.font-italic {
  font-style: italic !important;
}

.text-white {
  color: #fff !important;
}

.text-primary {
  color: #158cba !important;
}

a.text-primary:hover, a.text-primary:focus {
  color: #0d5875 !important;
}

.text-secondary {
  color: #f0f0f0 !important;
}

a.text-secondary:hover, a.text-secondary:focus {
  color: #cacaca !important;
}

.text-success {
  color: #28b62c !important;
}

a.text-success:hover, a.text-success:focus {
  color: #1a771d !important;
}

.text-info {
  color: #75caeb !important;
}

a.text-info:hover, a.text-info:focus {
  color: #32b0e1 !important;
}

.text-warning {
  color: #ff851b !important;
}

a.text-warning:hover, a.text-warning:focus {
  color: #ce6000 !important;
}

.text-danger {
  color: #ff4136 !important;
}

a.text-danger:hover, a.text-danger:focus {
  color: #e90d00 !important;
}

.text-light {
  color: #f6f6f6 !important;
}

a.text-light:hover, a.text-light:focus {
  color: #d0d0d0 !important;
}

.text-dark {
  color: #555 !important;
}

a.text-dark:hover, a.text-dark:focus {
  color: #2f2f2f !important;
}

.text-body {
  color: #222 !important;
}

.text-muted {
  color: #999 !important;
}

.text-black-50 {
  color: rgba(0, 0, 0, .5) !important;
}

.text-white-50 {
  color: rgba(255, 255, 255, .5) !important;
}

.text-hide {
  font: 0 / 0 a;
  color: rgba(0, 0, 0, 0);
  text-shadow: none;
  background-color: rgba(0, 0, 0, 0);
  border: 0;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-break {
  word-break: break-word !important;
  word-wrap: break-word !important;
}

.text-reset {
  color: inherit !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

.navbar {
  border-style: solid;
  border-width: 0 1px 4px;
}

.bg-primary {
  border-color: #127ba3;
}

.bg-dark {
  border-color: #484848;
}

.bg-light {
  border-color: #f2f2f2;
  background-color: #fff !important;
}

.btn {
  text-transform: uppercase;
  border-style: solid;
  border-width: 0 1px 4px;
}

.btn:not(.disabled):hover {
  border-bottom-width: 3px;
  margin-top: 1px;
}

.btn:not(.disabled):active {
  border-bottom-width: 2px;
  margin-top: 2px;
}

.btn-primary {
  border-color: #127ba3;
}

.btn-secondary {
  border-color: #e3e3e3;
}

.btn-success {
  border-color: #23a127;
}

.btn-info {
  border-color: #5fc1e8;
}

.btn-danger {
  border-color: #ff291d;
}

.btn-warning {
  border-color: #ff7702;
}

.btn-light {
  border-color: #e9e9e9;
}

.btn-dark {
  border-color: #484848;
}

[class*="btn-outline"] {
  border-top-width: 1px;
}

.btn-group-vertical .btn + .btn:hover {
  border-top-width: 1px;
  margin-top: -1px;
}

.btn-group-vertical .btn + .btn:active {
  border-top-width: 2px;
  margin-top: -1px;
}

.text-secondary {
  color: #555 !important;
}

.blockquote-footer {
  color: #999;
}

.table-primary, .table-success, .table-info, .table-warning, .table-danger, .table-hover tbody .table-primary:hover, .table-hover tbody .table-success:hover, .table-hover tbody .table-info:hover, .table-hover tbody .table-warning:hover, .table-hover tbody .table-danger:hover, .table-hover tbody .table-dark:hover {
  color: #fff;
}

.table-primary, .table-primary > th, .table-primary > td {
  background-color: #158cba;
}

.table-secondary, .table-secondary > th, .table-secondary > td {
  background-color: #f0f0f0;
}

.table-light, .table-light > th, .table-light > td {
  background-color: #f6f6f6;
}

.table-dark, .table-dark > th, .table-dark > td {
  background-color: #555;
}

.table-success, .table-success > th, .table-success > td {
  background-color: #28b62c;
}

.table-info, .table-info > th, .table-info > td {
  background-color: #75caeb;
}

.table-danger, .table-danger > th, .table-danger > td {
  background-color: #ff4136;
}

.table-warning, .table-warning > th, .table-warning > td {
  background-color: #ff851b;
}

.table-active, .table-active > th, .table-active > td {
  background-color: rgba(0, 0, 0, .075);
}

.table-hover .table-primary:hover, .table-hover .table-primary:hover > th, .table-hover .table-primary:hover > td {
  background-color: #127ba3;
}

.table-hover .table-secondary:hover, .table-hover .table-secondary:hover > th, .table-hover .table-secondary:hover > td {
  background-color: #e3e3e3;
}

.table-hover .table-light:hover, .table-hover .table-light:hover > th, .table-hover .table-light:hover > td {
  background-color: #e9e9e9;
}

.table-hover .table-dark:hover, .table-hover .table-dark:hover > th, .table-hover .table-dark:hover > td {
  background-color: #484848;
}

.table-hover .table-success:hover, .table-hover .table-success:hover > th, .table-hover .table-success:hover > td {
  background-color: #23a127;
}

.table-hover .table-info:hover, .table-hover .table-info:hover > th, .table-hover .table-info:hover > td {
  background-color: #5fc1e8;
}

.table-hover .table-danger:hover, .table-hover .table-danger:hover > th, .table-hover .table-danger:hover > td {
  background-color: #ff291d;
}

.table-hover .table-warning:hover, .table-hover .table-warning:hover > th, .table-hover .table-warning:hover > td {
  background-color: #ff7702;
}

.table-hover .table-active:hover, .table-hover .table-active:hover > th, .table-hover .table-active:hover > td {
  background-color: rgba(0, 0, 0, .075);
}

.form-control {
  box-shadow: inset 0 2px rgba(0, 0, 0, .075);
}

.input-group-sm > .input-group-prepend .btn, .input-group-sm > .input-group-append .btn {
  font-size: .625rem;
}

.nav .open > a, .nav .open > a:hover, .nav .open > a:focus {
  border-color: rgba(0, 0, 0, 0);
}

.nav-tabs .nav-link {
  color: #222;
}

.nav-tabs .nav-link, .nav-tabs .nav-link.disabled, .nav-tabs .nav-link.disabled:hover, .nav-tabs .nav-link.disabled:focus {
  border-color: #f0f0f0;
  margin-top: 6px;
  transition: padding-bottom .2s ease-in-out, margin-top .2s ease-in-out, border-bottom .2s ease-in-out;
}

.nav-tabs .nav-link:not(.disabled):hover, .nav-tabs .nav-link:not(.disabled):focus, .nav-tabs .nav-link.active {
  border-bottom-color: rgba(0, 0, 0, 0);
  margin-top: 0;
  padding-bottom: calc(.5rem + 6px);
}

.nav-tabs.nav-justified > li {
  vertical-align: bottom;
}

.dropdown-menu {
  border-style: solid;
  border-width: 1px 1px 4px;
  margin-top: 0;
}

.breadcrumb {
  border: 1px solid #e3e3e3;
  border-width: 0 1px 4px;
}

.pagination > li > a, .pagination > li > span {
  color: #555;
  text-transform: uppercase;
  border-style: solid;
  border-width: 0 1px 4px;
  font-size: 12px;
  font-weight: 700;
  position: relative;
  top: 0;
}

.pagination > li > a:hover, .pagination > li > a:focus, .pagination > li > span:hover, .pagination > li > span:focus {
  border-bottom-width: 3px;
  text-decoration: none;
  top: 1px;
}

.pagination > li > a:active, .pagination > li > span:active {
  border-bottom-width: 2px;
  top: 2px;
}

.pagination > .disabled > a:hover, .pagination > .disabled > span:hover, .pagination > .disabled > a:active, .pagination > .disabled > span:active {
  border-style: solid;
  border-width: 0 1px 4px;
  top: 0;
}

.pager > li > a, .pager > li > a:hover, .pager > li > a:active, .pager > li > span, .pager > li > span:hover, .pager > li > span:active, .pager > .disabled > a, .pager > .disabled > a:hover, .pager > .disabled > a:active, .pager > .disabled > span, .pager > .disabled > span:hover, .pager > .disabled > span:active {
  border-left-width: 2px;
  border-right-width: 2px;
}

.close {
  opacity: .4;
  text-decoration: none;
}

.close:hover, .close:focus {
  opacity: 1;
}

.alert {
  color: #fff;
  border-style: solid;
  border-width: 0 1px 4px;
}

.alert-primary {
  background-color: #158cba;
  border-color: #127ba3;
}

.alert-secondary {
  background-color: #f0f0f0;
  border-color: #e3e3e3;
}

.alert-success {
  background-color: #28b62c;
  border-color: #23a127;
}

.alert-info {
  background-color: #75caeb;
  border-color: #5fc1e8;
}

.alert-danger {
  background-color: #ff4136;
  border-color: #ff291d;
}

.alert-warning {
  background-color: #ff851b;
  border-color: #ff7702;
}

.alert-dark {
  background-color: #555;
  border-color: #484848;
}

.alert-light {
  background-color: #f6f6f6;
  border-color: #e9e9e9;
}

.alert .alert-link {
  color: #fff;
  font-weight: 400;
  text-decoration: underline;
}

.alert-secondary, .alert-secondary a, .alert-secondary .alert-link, .alert-light, .alert-light a, .alert-light .alert-link {
  color: #222;
}

.badge-warning, .badge-info {
  color: #fff;
}

a.list-group-item-success.active {
  background-color: #28b62c;
}

a.list-group-item-success.active:hover, a.list-group-item-success.active:focus {
  background-color: #23a127;
}

a.list-group-item-warning.active {
  background-color: #ff851b;
}

a.list-group-item-warning.active:hover, a.list-group-item-warning.active:focus {
  background-color: #ff7702;
}

a.list-group-item-danger.active {
  background-color: #ff4136;
}

a.list-group-item-danger.active:hover, a.list-group-item-danger.active:focus {
  background-color: #ff291d;
}

.jumbotron {
  border: 1px solid #f0f0f0;
  box-shadow: inset 0 2px rgba(0, 0, 0, .05);
}

.modal .close, .toast .close, .modal .close:not(:disabled):not(.disabled):hover, .modal .close:not(:disabled):not(.disabled):focus, .toast .close:not(:disabled):not(.disabled):hover, .toast .close:not(:disabled):not(.disabled):focus {
  color: #000;
}

footer .social-links svg {
  width: 25px;
  height: 25px;
}

footer .social-links a:hover {
  text-decoration: none;
}

footer .social-links .bi-linkedin {
  fill: #0077b5;
}

footer .social-links .bi-github {
  fill: #24292e;
}

footer .copyright {
  margin-top: .5rem;
}

main h1 {
  text-align: center;
}

main p {
  text-align: center;
  font-size: 1.47656rem;
  font-weight: 300;
}

@media (max-width: 1200px) {
  main p {
    font-size: calc(1.27266rem + .271875vw);
  }
}

@media (min-width: 768px) {
  html {
    height: 100%;
  }
}

/*# sourceMappingURL=index.08a5153b.css.map */
